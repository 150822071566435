import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import cx from "clsx";

// components
import { Header } from "@/components/organisms";

// styles
import { useStyles } from "./BaseLayout.styles";

const { Footer, Content } = Layout;

interface BaseLayoutProps {
  className?: string;
}

const BaseLayout = withAuthenticationRequired(
  ({ className }: BaseLayoutProps) => {
    const styles = useStyles();

    return (
      <Layout
        className={cx({
          [styles.layout]: true,
          [className || ""]: className,
        })}
      >
        <Header className={styles.header} />
        <Content className={styles.content}>
          <Outlet />
        </Content>
        <Footer className={styles.footer}>
          Omega Consulting. &copy; All Rights Reserved
        </Footer>
      </Layout>
    );
  }
);

export { BaseLayout };
